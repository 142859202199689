










































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import {PlatformType} from '@/model/resource/PlatformType'
import {PlatformTypeCollection} from '@/model/collection/PlatformTypeCollection'
import {ListPlatformTypeSchema} from '@/schema/resource/PlatformType/ListPlatformTypeSchema'

@Component({})
export default class ListPlatformTypeView extends Mixins(MixinRouteMatch) {
  schema = new ListPlatformTypeSchema()
  collection = new PlatformTypeCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.setOrderBy('order')
    await this.populate()
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: PlatformType) {
    this.$nav.pushByName('editPlatformType', item.$id)
  }

  async removeItem(item: PlatformType) {
    await this.$dialog.remove(item)
    await item.removePlatformType()
    await this.collection.queryAsPage()
  }

  async populate() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
